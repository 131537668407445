



































































































import {
    Component,
    Vue,
    Watch,
} from 'vue-property-decorator';
import MapView from '@/components/core/Map.vue';
import { User } from "@/store/user";
import { Auth } from "@/store/auth";
import { Core } from "@/store/core";
import { Map } from "@/store/map";
import { Product } from "@/store/product";
import {
    City
} from "@/store/city";
import _ from 'lodash'
@Component({
    components: { MapView },
    computed: {},
})

export default class PostSaller extends Vue {

    advanceSearch: boolean = true
    width: any = this.$vuetify.breakpoint.width

    dialog: boolean = false
    priceType: any = 1
    saleType: any = null
    page: number = 1
    panel: number[] = [0, 1]
    allPages: number = 1
    async created() {
        // await Core.switchLoad(true)
        await this.run()
        // await Core.switchLoad(false)
    }

    products: any = null
    response: boolean = false;
    category: any = ''
    productType: number = 0
    productsType: any = null

    async run() {
        // await Core.switchLoad(true)
        await this.loadCategory();
        await this.loadProvinces();
        this.saleType = await Product.SaleType
        this.productsType = await Product.ProductType
        await this.loadProducts()
        await this.getType();
        await this.getCat();
        // await Core.switchLoad(false)
        this.response = true
    }

    @Watch('chooseCategories')
    async changeCategory(val: any) {
        this.category = `category=${this.chooseCategories.toString()}`
        await this.loadProducts()
    }

    @Watch('productType')
    async changeProductsType(val: any) {
        await this.loadProducts()
    }

    async getType() {
        let typeProduct: any = this.$route.query.type;
        if (typeProduct) {
            let choose = _.find(this.productsType, { name: typeProduct })
            this.productType = choose.id;
        }
    }

    async getCat() {
        let typeProduct: any = this.$route.query.cat;
        if (typeProduct) {
            let choose = _.find(this.categories, { id: Number(typeProduct) })
            this.chooseCategories = _.map(choose.detail, (val: any) => { return val.id })

        }
    }

    price: any = ''
    price_low: any = ''
    price_height: any = ''
    async changepriceType() {
        if (this.priceType == true) {
            this.price = `&price_in=${Number(this.price_low)-1}&price_out=${Number(this.price_height)+1}`
        } else {
            this.price = `&price_start=${Number(this.price_low)-1}&price_end=${Number(this.price_height)+1}`
        }
        await this.loadProducts()
    }

    async loadProducts() {
        let search = this.$route.query.search
        search = (search) ? `search=${search}` : ''
        this.products = await Core.getHttp(`/api/default/products/?&product_type=${this.productType}&${this.category}${search}${this.price}&farm__province=${this.province}`)
        // this.allPages = Math.ceil((this.products.count / 9))
        this.allPages = Math.ceil((this.products.count / this.products.result.length))

    }
    categories: any = null
    chooseCategories: any = []
    async loadCategory() {
        this.categories = await Core.getHttp(`/api/default/categories/`)
    }
    provinces: any = null
    province: any = '';
    async loadProvinces() {
        this.provinces = await Core.getHttp(`/api/default/province/`)
    }

    private async handlePageChange(value: any) {
        let search = this.$route.query.search
        search = (search) ? `search=${search}` : ''
        this.products = await Core.getHttp(`/api/default/products/?&product_type=${this.productType}&${this.category}${search}${this.price}&farm__province=${this.province}&page=${value}`)

    }

    private async resetPage() {
        this.$router.push('/product?search=')
        await location.reload();
    }

    ximg(file: any) {
        return (file) ? process.env.VUE_APP_SERVER + '/' + file : 'https://images.pexels.com/photos/4052387/pexels-photo-4052387.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940'
    }

}
